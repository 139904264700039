import React, { forwardRef, RefObject } from "react"
import styled from "styled-components"

interface INavButtonsProps {
  className?: string
  fontSize?: string
  color?: string
  left?: string
  right?: string
  addclass?: string
}

 const NavNext: React.FC<INavButtonsProps> =({ className, addclass }, ref) => {
  return (
    <div className={`${className} ${addclass}`}>
      <i className="fas fa-chevron-right" />
    </div>
  )
}
const NavPrev: React.FC<INavButtonsProps> =({ className, addclass }, ref) => {
  return (
    <div className={`${className} ${addclass}`}>
      <i className="fas fa-chevron-left" />
    </div>
  )
}

export const NavNextButton = styled(NavNext)`
  position:absolute;
  top:50%;
  transform: translateY(-50%);
  right:${props => props.right ? props.right : "10px"};
  z-index:1000;
  i {
    color: ${props => props.color ? props.color : "white"};
    font-size: ${props => props.fontSize ? props.fontSize: "35px"};
  }
`
export const NavPrevtButton = styled(NavPrev)`
  position:absolute;
  top:50%;
  transform: translateY(-50%);
  left:${props => props.left ? props.left : "10px"};
  z-index:1000;
  i {
    color: ${props => props.color ? props.color : "white"};
    font-size: ${props => props.fontSize ? props.fontSize : "35px"};
  }
`
